import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Splash from "./pages/Splash";
import Lightbox from "./pages/Lightbox";
import "./App.css";
import platforms from "./settings/platforms";

function App() {
  const [lightboxContents, setLightboxContents] = useState(null);
  const [provider, setProvider] = useState(null);

  const handleOAuth = async (platformConfig) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code && platformConfig.authenticate) {
      try {
        const token = await platformConfig.getToken(code);

        if (!token) {
          console.log("no token");
          return;
        }
        console.log(
          `Received token for ${platformConfig.platformName}:`,
          token
        );
        if (platformConfig.actions) {
          const currentDate = new Date();
          for (const action of platformConfig.actions) {
            if (typeof action.action === "function") {
              // Check if the action has an activeOn date and if it's in the future
              // if (action.activeOn && new Date(action.activeOn) > currentDate) {
              //   console.log(`Action ${action.name} is not active yet.`);
              //   continue; // Skip this action as it's not active yet
              // }
              const response = await action.action(token, action.value);
              console.log(response);
            }
          }
        }
        setProvider(platformConfig.platformName);
      } catch (error) {
        console.error(
          `Error during OAuth for ${platformConfig.platformName}:`,
          error
        );
      }
    }
  };

  useEffect(() => {
    console.log(platforms);
    const pathname = window.location.pathname;
    const platformConfig = platforms.find((p) => p.callbackPath === pathname);
    if (platformConfig) {
      handleOAuth(platformConfig);
    }
  }, []);

  const props = { setLightboxContents, provider };

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/*" element={<Splash {...props} />} />
        </Routes>
      </Router>
      <Lightbox
        lightboxContents={lightboxContents}
        setLightboxContents={setLightboxContents}
      />
    </div>
  );
}

export default App;
