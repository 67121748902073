
export default function PlatformActions({actions, authenticate}) {


  const handleClick = () => {
    authenticate();
  };

    return (
        <fieldset className=" border-gray-200">
            <legend className="sr-only">Notifications</legend>
            <div className="divide-y divide-gray-200">

                {actions.map((action) => {
                    return (
                        <div key={`action-${action.name}`} className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor="comments" className="font-medium text-gray-900">
                                    {action.name}
                                </label>
                                <p id="comments-description" className="text-gray-500">
                                    {action.description}
                                </p>
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                                <input
                                    // onClick={setItemChecked}
                                    defaultChecked
                                    id={action.name}
                                    aria-describedby={`${action.name}-description`}
                                    name={action.name}
                                    type="checkbox"
                                    className="h-6  w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                        </div>
                    )
                })}
            </div>

            {/* <a href='/spotify'> */}
                <div onClick={() => { handleClick() }} className={` mt-3 bg-green-500 flex items-center justify-center gap-2 py-4  font-semibold py-2 px-4 border border-gray-300 rounded-xl w-full text-white shadow`}>
                    <span>
                        Continue
                    </span>
                </div>
            {/* </a> */}
        </fieldset>
    )
}