// src/auth/youtube.js

const CLIENT_ID = process.env.REACT_APP_YOUTUBE_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_YOUTUBE_CLIENT_SECRET;
const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}/oauth2/callback`;

const authenticate = async () => {
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/youtube.force-ssl&access_type=offline`;
  // console.log(authUrl)
  // Redirect user to the authentication page
  window.location.href = authUrl;
};

const exchangeCodeForToken = async (code) => {
  const response = await fetch("https://oauth2.googleapis.com/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `code=${code}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=${REDIRECT_URI}&grant_type=authorization_code`,
  });

  const data = await response.json();
  return data.access_token;
};

const subscribeToChannel = async (accessToken, channelId) => {
  const response = await fetch(
    "https://www.googleapis.com/youtube/v3/subscriptions?part=snippet",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        snippet: {
          resourceId: {
            kind: "youtube#channel",
            channelId: channelId,
          },
        },
      }),
    }
  );

  return response.json();
};

const likeVideo = async (accessToken, videoId) => {
  // Example implementation, adjust according to the actual API specifications
  const response = await fetch(
    "https://www.googleapis.com/youtube/v3/videos/rate",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: videoId,
        rating: "like",
      }),
    }
  );

  return "sucsess";
};

export { authenticate, exchangeCodeForToken, subscribeToChannel, likeVideo };
