import { generateRandomString, generateCodeChallenge } from "../util";

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const redirectUri = `${process.env.REACT_APP_REDIRECT_URL}/spotify`;

const authenticate = async () => {
  let codeVerifier = generateRandomString(128);

  await generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    let state = generateRandomString(16);
    let scopes = [
      "user-library-modify",
      "user-follow-modify",
      "playlist-modify-public",
      "playlist-modify-private",
    ].join(" ");

    let scopes_encoded = encodeURIComponent(scopes);

    localStorage.setItem("code_verifier", codeVerifier);

    let args = new URLSearchParams({
      response_type: "code",
      client_id: clientId,
      scope: scopes_encoded,
      redirect_uri: redirectUri,
      state: state,
      code_challenge_method: "S256",
      code_challenge: codeChallenge,
    });

    args = decodeURIComponent(args);

    window.location = "https://accounts.spotify.com/authorize?" + args;
  });
};

const generateToken = async (code) => {
  let codeVerifier = localStorage.getItem("code_verifier");

  let body = new URLSearchParams({
    grant_type: "authorization_code",
    code: code,
    redirect_uri: redirectUri,
    client_id: clientId,
    code_verifier: codeVerifier,
  });

  return fetch("https://accounts.spotify.com/api/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data.error) return data.access_token;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const refreshToken = async (refresh_token, client_id) => {
  const body = new URLSearchParams({
    grant_type: "refresh_token",
    refresh_token: refresh_token,
    client_id: client_id,
  });

  const response = await fetch("https://accounts.spotify.com/api/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  });

  if (!response.ok) {
    throw new Error("HTTP status " + response.status);
  }

  const data = await response.json();
  localStorage.setItem("access_token", data.access_token);

  return data;
};

async function spotifyApiCall(url, method, access_token, body = null) {
  try {
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    };

    const config = {
      method: method,
      headers: headers,
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    const response = await fetch(url, config);

    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }

    return "success";
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function saveAlbum(access_token, albumId) {
  return spotifyApiCall(
    `https://api.spotify.com/v1/me/albums?ids=${albumId}`,
    "PUT",
    access_token
  );
}

async function saveTrack(access_token, trackId) {
  return spotifyApiCall(
    `https://api.spotify.com/v1/me/tracks?ids=${trackId}`,
    "PUT",
    access_token
  );
}

async function followArtist(access_token, artistId) {
  return spotifyApiCall(
    `https://api.spotify.com/v1/me/following?type=artist&ids=${artistId}`,
    "PUT",
    access_token
  );
}

async function followPlaylist(access_token, playlistId) {
  return spotifyApiCall(
    `https://api.spotify.com/v1/playlists/${playlistId}/followers`,
    "PUT",
    access_token,
    { public: true }
  );
}

export {
  authenticate,
  generateToken,
  refreshToken,
  saveAlbum,
  saveTrack,
  followArtist,
  followPlaylist,
};
