import React from 'react';

function Lightbox({ lightboxContents, setLightboxContents }) {

if(!lightboxContents){
  return <></>
}

  return (<>
    <div className='fixed fade-in  flex items-center justify-center  w-screen h-screen z-20 top-0 bg-black backdrop-blur-md bg-opacity-40 p-5 '>
      <div className='w-full p-5 max-w-[400px]  fade-in-translate anim-delay-500   bg-white rounded-xl'>
        {lightboxContents}
        <div onClick={() => { setLightboxContents(null) }} className={` mt-3 bg-white flex items-center justify-center gap-2 py-4  font-semibold py-2 px-4 border border-gray-300 rounded-xl w-full text-black shadow`}>
          <span>
            Close
          </span>
        </div>
      </div>
    </div>
  </>)
}

export default Lightbox;


