import React, { useState } from "react";
import Links from "./Links";

function Splash(props) {
  return (
    <>
      <div
        className="h-[450px]  w-screen top-0 fixed bg-center bg-cover fade-in-slow"
        style={{ backgroundImage: `url(/artwork.jpeg)` }}
      >
        <div className="w-full h-[25%] absolute bottom-0 bg-gradient-to-t from-white"></div>
      </div>
      <div className="container translate-in  max-w-[500px] mx-auto relative w-full z-10 mt-[320px]  md:shadow-2xl sm:h-80  mb-[100px] ">
        <div className="bg-white fade-in-translate-slow	 rounded-2xl backdrop-blur-lg bg-opacity-40 ">
          <Links {...props} />

          <div
            onClick={() => {
              localStorage.clear("clickedButtons");
              localStorage.clear("scrollPosition");
              window.location = "/";
            }}
            className="flex text-gray-500 justify-center py-10"
          >
            <span>Click to reset</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Splash;
