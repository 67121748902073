import { useState } from "react";


export default function Coupon() {

  const [copyToolTip, setCopyToolTip] = useState(false);
  const couponCode = 'X3E5GdL';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(couponCode);

    setCopyToolTip(true)
    setTimeout(() => {
      setCopyToolTip(false)
    }, 500);
  }

    return (<div>
        <div className=' bg-gray-300 gap-5 flex items-center  relative justify-center rounded-xl py-4 text-3xl font-bold'>
            <span>
                {couponCode}
            </span>
            <button data-tooltip-target="tooltip-default" onClick={copyToClipboard} className={`absolute text-gray-600 right-5  transition active:bg-black active:bg-opacity-10 p-2 rounded`}>
                <span class={`${copyToolTip ? 'opacity-100' : 'opacity-0'} transition pointer-events-none tooltip absolute text-xs rounded shadow-lg p-2 bg-black rounded bg-opacity-80 text-white -translate-x-1/2  -mt-10`}>Copied</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                </svg>
            </button>
        </div>
        <a href='https://shop.glassanimals.com/'>
            <div className={` mt-3 bg-white flex items-center justify-center gap-2 py-4  font-semibold py-2 px-4 border border-gray-300 rounded-xl w-full text-black shadow`}>
                <span>
                    Go to store
                </span>
            </div>
        </a>
    </div>
    )
}