// src/auth/reddit.js

const CLIENT_ID = process.env.REACT_APP_REDDIT_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_REDDIT_CLIENT_SECRET;
const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}/oauth2/reddit/callback`;

const authenticate = async () => {
  const authUrl = `https://www.reddit.com/api/v1/authorize?client_id=${CLIENT_ID}&response_type=code&state=random_string&redirect_uri=${REDIRECT_URI}&duration=permanent&scope=subscribe`;
  // Redirect user to the authentication page
  window.location.href = authUrl;
};

const exchangeCodeForToken = async (code) => {
  const basicAuth = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);
  const response = await fetch("https://www.reddit.com/api/v1/access_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${basicAuth}`,
    },
    body: `grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URI}`,
  });

  const data = await response.json();
  return data.access_token;
};

const joinSubreddit = async (accessToken, subredditName) => {
  const response = await fetch(
    `https://oauth.reddit.com/api/subscribe?sr_name=${subredditName}&action=sub`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": "YourUserAgentHere", // Replace 'YourUserAgentHere' with your app's user agent
      },
    }
  );

  if (response.ok) {
    return { success: true };
  } else {
    const errorData = await response.json();
    return { success: false, error: errorData };
  }
};

export { authenticate, exchangeCodeForToken, joinSubreddit };
