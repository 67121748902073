import * as youtube from "../auth/youtube";
import * as spotify from "../auth/spotify";
import * as twitter from "../auth/twitter";
import * as reddit from "../auth/reddit";
const platforms = [
  {
    platformName: "newsletter",
    name: "Sign up to Email Database",
    image: "/assets/mail.svg",
    url: "https://example.com/newsletter-signup", // Replace with your actual URL
  },
  {
    platformName: "discord",
    name: "Join Discord Server",
    image: "/assets/discord.png",
    url: process.env.REACT_APP_DISCORD_URL,
  },
  {
    platformName: "reddit",
    name: "Join Reddit community",
    image: "/assets/reddit.svg",
    callbackPath: "/oauth2/reddit/callback",
    authenticate: reddit.authenticate,
    getToken: reddit.exchangeCodeForToken,
    actions: [
      {
        name: "Follow Subreddit",
        value: "glassanimals", // Replace with actual Twitter username
        description: "We will follow a Reddit page on your behalf.",
        action: (accessToken, value) =>
          reddit.joinSubreddit(accessToken, value),
      },
    ],
  },
  {
    platformName: "Instagram",
    name: "Follow on Instagram",
    image: "/assets/instagram.svg",
    url: "https://www.instagram.com/glassanimals/", // Replace with your actual URL
  },
  {
    platformName: "TikTok",
    name: "Follow on Tik Tok",
    image: "/assets/tiktok.svg",
    url: "https://www.tiktok.com/@glassanimalsofficial", // Replace with your actual URL
  },
  {
    platformName: "twitter",
    name: "Follow on Twitter",
    image: "/assets/twitter.svg",
    callbackPath: "/oauth2/twitter/callback",
    authenticate: twitter.authenticate,
    getToken: twitter.exchangeCodeForToken,
    actions: [
      {
        name: "Follow user",
        value: "USERNAME", // Replace with actual Twitter username
        description: "We will follow a Twitter user on your behalf.",
        action: (accessToken, value) =>
          twitter.followTwitterUser(accessToken, value),
      },
    ],
  },
  {
    platformName: "spotify",
    name: "Save on Spotify",
    image: "/assets/spotify.png",
    callbackPath: "/spotify",
    authenticate: spotify.authenticate,
    getToken: spotify.generateToken,
    actions: [
      {
        name: "Save album",
        value: "6qb9MDR0lfsN9a2pw77uJy", // Replace with actual album ID
        description: "Save an entire album to your personal library.",
        action: (accessToken, value) => spotify.saveAlbum(accessToken, value),
      },
      {
        name: "Pre-save song",
        activeOn: "2024-01-27", // Example date, set this dynamically
        // todo set time
        value: "1p8mrJtkM4KFa72LLQyZyP",
        description:
          "Pre-save an upcoming single and automatically add it to your library on its release date.",
        action: (accessToken, value) => spotify.saveTrack(accessToken, value),
      },
      {
        name: "Follow artist",
        description:
          "Stay updated on an artist's latest releases, concerts, and news.",
        value: "4yvcSjfu4PC0CYQyLy4wSq",
        action: (accessToken, value) =>
          spotify.followArtist(accessToken, value),
      },
      {
        name: "Follow playlist",
        description:
          "Easily access and discover curated playlists made by the artist",
        value: "2MP2OYle2oSq00RdeN24t5",
        action: (accessToken, value) =>
          spotify.followPlaylist(accessToken, value),
      },
    ],
  },

  {
    platformName: "youtube",
    name: "Subscribe on Youtube",
    image: "/assets/youtube.svg",
    callbackPath: "/oauth2/callback",
    authenticate: youtube.authenticate,
    getToken: youtube.exchangeCodeForToken,
    actions: [
      {
        name: "Subscribe to channel",
        value: "UCJTs-KheOMNstaGrDL4K55Q", // Replace with actual YouTube channel ID
        description: "Subscribe to a YouTube channel.",
        action: (accessToken, value) =>
          youtube.subscribeToChannel(accessToken, value),
      },
      {
        name: "Like a video",
        value: "iFbbnzR2E88", // Replace with actual YouTube video ID
        description: "Like a video on YouTube.",
        action: (accessToken, value) => youtube.likeVideo(accessToken, value),
      },
    ],
  },
];

export default platforms;
