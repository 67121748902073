import React, { useEffect, useState } from "react";
import Spotify from "../auth/spotify";
import Coupon from "../popups/Coupon";
import PlatformActions from "../popups/PlatformActions";
import platforms from "../settings/platforms";

function Links(props) {
  const [isShaking, setIsShaking] = useState(false);
  const [allActivated, setAllActivated] = useState(false);
  const [clickedButtons, setClickedButtons] = useState(() => {
    const saved = localStorage.getItem("clickedButtons");
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });

  const { setLightboxContents, provider } = props;

  useEffect(() => {
    if (provider === null) {
      return;
    }

    console.log("Platform:", provider);

    const providerIndex = platforms.findIndex((platform) => {
      return platform.platformName.toLowerCase() === provider.toLowerCase();
    });

    if (providerIndex !== -1) {
      if (provider === "youtube") {
        console.log("youtube");
      }

      // const requests = localStorage.getItem('providerRequests');
      // requests.map((request) => request()); // We are storing this so we probably need to re map to the true function

      // Do actions here
      // Possibly we can have a redirect uri with parameters but spotify may not allow this, so we could store the request in local storage

      setClickedButtons((prevState) => {
        const newState = [...prevState];
        newState[providerIndex] = true;
        localStorage.setItem("clickedButtons", JSON.stringify(newState)); // Store in local storage
        return newState;
      });
    } else {
      console.log(`No match found for platform: ${provider}`);
    }
  }, [provider, platforms]);

  const handleEndClick = () => {
    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 500);
  };

  const handleActionClick = (platformIndex) => {
    if (!clickedButtons[platformIndex]) {
      if (
        platforms[platformIndex] &&
        typeof platforms[platformIndex].actions === "object"
      ) {
        setLightboxContents(
          <PlatformActions
            platform={platforms[platformIndex].name}
            authenticate={platforms[platformIndex].authenticate}
            actions={platforms[platformIndex].actions}
          />
        );
      } else {
        if (platforms[platformIndex].url) {
          window.open(platforms[platformIndex].url, "_blank");

          setClickedButtons((prevState) => {
            const newState = [...prevState];
            newState[platformIndex] = true;
            localStorage.setItem("clickedButtons", JSON.stringify(newState)); // Store in local storage
            return newState;
          });
        }
      }
    }
  };

  useEffect(() => {
    const allButtonsClicked = platforms.every(
      (_, platformIndex) => clickedButtons[platformIndex]
    );
    setAllActivated(allButtonsClicked);
  }, [clickedButtons]);

  // () => { Spotify.saveAlbum('5bfpRtBW7RNRdsm3tRyl3R') }/

  return (
    <div>
      <div className="p-4 text-xl  font-bold text-center  ">
        <div className=" flex flex-col gap-3   w-full ">
          <div className="bg-[#000] gap-4 flex p-5 pt-10 flex-col justify-center items-center text-white bg-opacity-80   w-full rounded-xl ">
            <img className="w-full" alt="logo" src={"/logo.png"} />
            <span className="text-md mt-2 text-white font-medium">
              Join our community by connecting to us below.
            </span>

            {allActivated ? (
              <div className=" mt-3 gradient-border w-full rounded-xl">
                <div
                  onClick={() => {
                    setLightboxContents(<Coupon />);
                  }}
                  className={`   z-10  relative bg-white flex items-center justify-center gap-2 py-4  font-semibold py-2 px-4 border border-white rounded-xl w-full text-black shadow`}
                >
                  <span>Get code</span>
                </div>
              </div>
            ) : (
              <div
                onClick={handleEndClick}
                className={` mt-3 flex items-center justify-center gap-2 py-4  font-semibold py-2 px-4 border border-gray-300 rounded-xl w-full text-gray-200 shadow  ${
                  isShaking ? "shake" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
                <span>Like items to unlock</span>
              </div>
            )}
          </div>

          <div className="flex flex-wrap">
            {platforms.map((platform, platformIndex) => (
              <div className="p-1 w-1/3 ">
                <div
                  key={`action-${platformIndex}`}
                  onClick={() => handleActionClick(platformIndex)}
                  className={`bg-white aspect-square gap-2  flex flex-col justify-center items-center	 py-2  text-gray-800 font-semibold  px-2 border transition border rounded-xl shadow  ${
                    clickedButtons[platformIndex]
                      ? " border-transparent text-white"
                      : "border-gray-300 hover:bg-gray-100"
                  } `}
                  style={{
                    backgroundColor: clickedButtons[platformIndex]
                      ? "rgb(250 13 108)"
                      : "white",
                  }}
                >
                  <img
                    className={`w-10   ${
                      clickedButtons[platformIndex] ? "brightness-0 invert" : ""
                    }`}
                    src={platform.image}
                  />
                  <div className="text-xs"> {platform.name} </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;
