import { generateRandomString, generateCodeChallenge } from "../util";

const CLIENT_ID = process.env.REACT_APP_TWITTER_CLIENT_ID;
const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}/oauth2/twitter/callback`;

const authenticate = async () => {
  let codeVerifier = generateRandomString(128);

  await generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    let state = generateRandomString(16);
    let scopes = "tweet.read users.read follows.read follows.write";
    // let encodedScopes = encodeURIComponent(scopes);

    localStorage.setItem("twitter_code_verifier", codeVerifier);

    let args = new URLSearchParams({
      response_type: "code",
      client_id: CLIENT_ID,
      scope: scopes,
      redirect_uri: REDIRECT_URI,
      state: state,
      code_challenge_method: "plain",
      code_challenge: codeChallenge,
    });

    window.location = "https://twitter.com/i/oauth2/authorize?" + args;
  });
};

const exchangeCodeForToken = async (code) => {
  return "woo";
  let codeVerifier = localStorage.getItem("twitter_code_verifier");

  let body = {
    grant_type: "authorization_code",
    code: code,
    redirect_uri: REDIRECT_URI,
    client_id: CLIENT_ID,
    code_verifier: codeVerifier,
  };

  return fetch("https://api.twitter.com/2/oauth2/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data.error) return data.access_token;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const followTwitterUser = async (accessToken, screenName) => {
  return "sucsess";
  const response = await fetch(
    `https://api.twitter.com/2/users/by/username/${screenName}/follow`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
};

export { authenticate, exchangeCodeForToken, followTwitterUser };
